import React from "react";
import ActuCard from "./ActuCard";

function ActuListPage({ articles }) {
  return (
    <section className="overflow-hidden py-8 container mx-auto relative">
      <div className="flex flex-col lg:flex-row justify-around items-center flex-wrap gap-4 lg:gap-8">
        {articles.map((actu) => (
          <ActuCard key={actu._id} article={actu} />
        ))}
      </div>
    </section>
  );
}

export default ActuListPage;
