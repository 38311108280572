import React from "react";
import Header from "../components/Header";
import HeroBienv from "../components/HeroBienv";
import LayoutHero from "../components/LayoutHero";
import Footer from "../components/Footer";
import Realisation from "../components/Realisation";
import Partenaires from "../components/Partenaires";
import Stat from "../components/Stat";
import ContactMe from "../components/ContactMe";
import Activite from "../components/Activite";
import ActuList from "../components/ActuList";
import Chemin from "../components/Chemin";

function Accueil() {
  const id1 = "Apropos";
  const text1 =
    "Tout a commencé par une phase d'exploration des <strong>possibilités de partenariat entre les établissements supérieurs d'enseignement et notre entreprise.</strong> Notre naissance a eu lieu à <strong>l'École Polytechnique de Dakar,</strong> un cadre idéal pour cette exploration allant bien au-delà du simple transfert de compétences techniques. Ce partenariat initial s'est rapidement élargi pour inclure d'autres institutions supérieures formant <strong>des ingénieurs en mécanique, informatique et électronique.</strong> Nous nous engageons à <strong>partager notre savoir-faire et nos expertises,</strong> créant ainsi des <strong>liens essentiels pour le développement de l'industrie sénégalaise.</strong> C'est l'initiative G-NI's Factory, créée en 2022.";
  const title1 = "L'exploration de Nicomatic au Sénégal";
  const text2 =
    "La mission initiale de G-NI’s Factory étant <strong>d'accompagner les startups innovantes,</strong> souvent en manque de moyens, dans la concrétisation de leurs idées de fabrication. Nicomatic met à disposition <strong>son expertise et ses connaissances</strong> pour les aider à prototyper leurs produits. Au fil du temps, <strong>nos partenariats ont évolué vers la fabrication de machines industrielles,</strong> reflétant notre engagement envers le développement de l'industrie locale. En 2024, <strong>G-NI's Factory est devenue officiellement une filiale du groupe Nicomatic,</strong> un leader mondialement reconnu dans la connectique pour les secteurs de l'aérospatiale, du spatial et du militaire, présent dans plus de 20 pays.";
  return (
    <div className=" overflow-hidden bg-fond ">
      <Header />
      <ContactMe />
      <HeroBienv />
      <Chemin />
      <Stat />
      <Activite />
      <Partenaires />

      <ActuList />
      <Footer />
    </div>
  );
}

export default Accueil;
