import { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaXmark, FaChevronDown } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Header() {
  const [activeLink, setActiveLink] = useState("accueil");
  const [navbar, setNavbar] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.offsetHeight;
        if (window.scrollY >= top && window.scrollY < top + height) {
          setActiveLink(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setActiveLink]);

  const toggleNavbar = () => {
    setNavbar((prev) => !prev);
  };

  const toggleSubMenu = () => {
    setShowSubMenu((prev) => !prev);
  };

  const active =
    "p-2 text-bleu font-extrabold font-inter hover:text-bleu whitespace-nowrap";
  const inactive =
    "p-2 text-gris font-extrabold font-inter hover:text-bleu whitespace-nowrap";

  return (
    <header className="flex w-full justify-between lg:justify-around items-center fixed bg-fond py-2 shadow z-50 lg:py-4 px-4">
      <a href="https://nicomaticsenegal.com/">
        <img
          src={process.env.PUBLIC_URL + "/assets/Senegal.png"}
          alt="logo"
          id="logo"
          className="w-48"
        />
      </a>
      <GiHamburgerMenu
        className="text-bleu text-2xl lg:hidden cursor-pointer"
        onClick={toggleNavbar}
      />
      <nav
        role="navigation"
        className={`${navbar ? "block" : "hidden"} lg:block`}
      >
        <ul className="absolute inset-0 h-screen bg-fond items-center justify-center lg:h-fit lg:relative flex-col lg:flex-row flex lg:justify-around w-full space-x-8 font-extrabold text-gris">
          <FaXmark
            onClick={toggleNavbar}
            className="text-bleu text-2xl lg:hidden cursor-pointer absolute right-2 top-2"
          />
          <li className="relative">
            <a
              href="https://nicomaticsenegal.com/#"
              className={
                activeLink === "accueil" ? `${active} font-bold` : inactive
              }
              onClick={toggleSubMenu}
            >
              Accueil
              <FaChevronDown
                className={`inline ml-2 ${showSubMenu ? "rotate-180" : ""}`}
              />
            </a>
            {showSubMenu && (
              <ul className="absolute top-full left-0 mt-2 bg-fond shadow-lg border rounded-lg">
                <li>
                  <a
                    href="https://nicomaticsenegal.com/#Apropos"
                    className={activeLink === "factory" ? active : inactive}
                    onClick={() => setActiveLink("factory")}
                  >
                    Notre Histoire
                  </a>
                </li>
                <li>
                  <a
                    href="https://nicomaticsenegal.com/#Activite"
                    className={activeLink === "consulting" ? active : inactive}
                    onClick={() => setActiveLink("consulting")}
                  >
                    Nos Services
                  </a>
                </li>
                <li>
                  <a
                    href="https://nicomaticsenegal.com/#Partenaires"
                    className={activeLink === "campus" ? active : inactive}
                    onClick={() => setActiveLink("campus")}
                  >
                    Nos Partenaires
                  </a>
                </li>
              </ul>
            )}
          </li>
          <li>
            <a
              href="https://factory.nicomaticsenegal.com/"
              className={activeLink === "" ? active : inactive}
            >
              Factory
            </a>
          </li>
          <li>
            <a
              href="https://consulting.nicomaticsenegal.com/"
              className={activeLink === "" ? active : inactive}
            >
              Consulting
            </a>
          </li>
          <li>
            <a
              href="https://campus.nicomaticsenegal.com/"
              className={activeLink === "" ? active : inactive}
            >
              Campus
            </a>
          </li>
          <li>
            <a
              href="https://studio.nicomaticsenegal.com/"
              className={activeLink === "" ? active : inactive}
            >
              Studio
            </a>
          </li>
        </ul>
      </nav>
      <div className="lg:block w-fit px-4 py-2 border-2 border-bleu rounded-full font-bold text-bleu shadow-lg">
        <Link to="/about">Qui sommes-nous</Link>
      </div>
    </header>
  );
}
