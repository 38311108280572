import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { login } from "../../../services/admin";
import { AuthContext } from "../../../context/AdminProvider";


function Login() {
    const navigate = useNavigate();
    const {setAuth} = useContext(AuthContext)
    const [credential, setCredential] = useState({
        email: "",
        password: "",
    });
    const onChange = (e) => {
        setCredential({
            ...credential,
            [e.target.name]: e.target.value,
        });
    };
    const [errorlogin, setErrorLogin] = useState(false);
    const [loadState, setLoadState] = useState(false);
    const handleLogin = async (e) => {
        e.preventDefault();
        const reponse = await login(credential);
        
        if (reponse.status === 200) {
            setAuth(true)
            navigate("/admin/home")
        }
    };
    return (
        <section className="h-screen w-full overflow-hidden flex justify-center items-center relative">
            <div class=" max-md:hidden absolute -top-12 -left-16 bg-jaune size-72 rounded-full blur-xl animate-pulse"></div>
            <div class="max-md:hidden absolute -bottom-12 -right-16 bg-jaune size-72 rounded-full blur-xl animate-pulse"></div>
            <div className="  max-md:h-screen max-md:w-full flex flex-col justify-center items-center  rounded-md  px-8  max-md:px-6 bg-bleu py-4 shadow-xl">
                <img
                    alt=""
                    src={process.env.PUBLIC_URL + "/assets/2.png"}
                    className="block mb-2 size-24 max-md:mt-28"
                />

                {/* <h1 className="text-xl font-extrabold lg:text-2xl text-left w-full text-white">
                   Se Connecter
                </h1> */}
                <form
                    onSubmit={handleLogin}
                    className=" space-y-4 max-w-xl mx-auto"
                >
                    {errorlogin && (
                        <div
                            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                            role="alert"
                        >
                            <strong className="font-bold">Hoops!</strong>
                            <span className="block sm:inline">
                                login ou mot de passe incorrect
                            </span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                <svg
                                    onClick={() => setErrorLogin(false)}
                                    className="fill-current h-6 w-6 text-red-500"
                                    role="button"
                                    xmlns="https://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <title>Close</title>
                                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                </svg>
                            </span>
                        </div>
                    )}
                    <div className="w-full text-left">
                        <label
                            htmlFor="email"
                            className="text-lg w-full text-white"
                        >
                            Email
                        </label>
                        <input
                            name="email"
                            id="email"
                            value={credential.email}
                            onChange={onChange}
                            required
                            type="email"
                            className="w-full px-1 py-2 rounded border border-bleu outline-none"
                        />
                    </div>
                    <div className="w-full text-left">
                        <label
                            htmlFor="password"
                            className="text-lg w-full text-white"
                        >
                            Mot de passe
                        </label>
                        <input
                            id="password"
                            name="password"
                            value={credential.password}
                            onChange={onChange}
                            required
                            type="password"
                            className="w-full px-1 py-2 rounded border border-bleu outline-none"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-3 font-semibold rounded bg-jaune  text-bleu"
                    >
                        Se connecter
                    </button>
                </form>
                <div className="mt-5 ">
                    <div className="text-center text-xs font-semibold text-white">
                        Mot de passe Oublié?
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
