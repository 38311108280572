import React, { createContext, useState, useEffect } from 'react';
import { isAuthenticated } from '../services/admin';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const isLogin = await isAuthenticated() ;
      setAuth(isLogin);
    };

    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
    {children}
  </AuthContext.Provider>
  );
};
