import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthContext, AuthProvider } from "./context/AdminProvider";
import ProtectedRoute from "./context/ProtectedRoute";
import Login from "./pages/Admin/components/Login";
import About from "./pages/About";
import Actualite from "./pages/Actualite";
import AdminRouter from "./pages/Admin/AdminRouter";
import Accueil from "./pages/Accueil";
import Article from "./pages/Article";

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<Accueil />} />
        <Route path="/about" element={<About />} />
        <Route path="/actualites" element={<Actualite />} />
        <Route path="/actualites/:_id" element={<Article />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin/*" element={<AdminRouter />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </Router>
  );
}

const AppWrapper = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWrapper;
