import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Partenaires = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "40px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="Partenaires" className="h-full w-full py-8">
      <div className="flex flex-col items-start p-5 mb-8 ">
        <h1 className="text-3xl text-center font-bold  font-inter">
          Nos Partenaires
        </h1>
        <p className="mt-4 text-xl text-left text-justify">
          Nos partenaires, leaders dans leurs domaines respectifs, nous
          apportent leur expertise et soutien, renforçant notre capacité à
          innover et à offrir des solutions de pointe. Grâce à ces
          collaborations, nous sommes en mesure de répondre aux besoins
          complexes de nos clients et de promouvoir le développement industriel
          au Sénégal.
        </p>
      </div>

      <div className="slider-container px-8">
        <Slider {...settings} className="">
          <div className="w-96 h-full flex px-4 items-center justify-center">
            <a
              href="https://www.nicomatic.com/fr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./images/nicomatic1.png"
                alt="Nicomatic Logo"
                className="mx-auto w-60 h-40"
              />
            </a>
          </div>
          <div className="w-96 h-full flex px-4 items-center justify-center">
            <a
              href="https://www.esp.sn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./images/esp1.png"
                alt="esp Logo"
                className="mx-auto w-50 h-40"
              />
            </a>
          </div>
          <div className="w-96 h-full flex px-4 items-center justify-center">
            <a
              href="https://oxytronic.fr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./images/oxy1.png"
                alt="oxy Logo"
                className="mx-auto w-80 h-40"
              />
            </a>
          </div>
          <div className="size-40 flex px-4 items-center justify-center">
            <a
              href="https://cfptsj.sn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./images/cfpt1.png"
                alt="cfpt Logo"
                className="mx-auto w-80 h-40"
              />
            </a>
          </div>

          <div className="w-96 h-full flex px-4 items-center justify-center">
            <a
              href="https://accuratenordic.com/nyhetsbrev/accurate-joins-the-nicomatic-group/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./images/accurate.png"
                alt="accurate Logo"
                className="mx-auto w-80 h-40"
              />
            </a>
          </div>
          <div className="w-96 h-full flex px-4 items-center justify-center">
            <a
              href="https://www.gauthierconnectique.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://www.gauthierconnectique.com/templates/emer_v2/images/logo.svg"
                alt="gauthier connectique Logo"
                className="mx-auto w-80 h-40"
              />
            </a>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Partenaires;
