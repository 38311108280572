import React, { useState, useEffect } from "react";
import Rechercher from "./components/Rechercher";
import { getArticles, deleteArticle } from "../../services/article";
import ArticleCard from "./components/ArticleCard";
import { Link } from "react-router-dom";

function GestionArticles() {
  const [listArticles, setListArticles] = useState([]);
  const [listFilteredArticles, setListFilteredArticles] = useState([]);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage, setArticlesPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const articles = await getArticles();
        setListArticles(articles);
        setCurrentPage(1); // Reset to page 1 on fetch
      } catch (error) {
        setError("Erreur lors de la récupération des articles");
        console.error("Erreur lors de la récupération des articles:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (search === "") {
      setListFilteredArticles(listArticles);
    } else {
      const filteredArticles = listArticles.filter((article) =>
        article.titre.toLowerCase().includes(search.toLowerCase())
      );
      setListFilteredArticles(filteredArticles);
    }
  }, [search, listArticles]);

  useEffect(() => {
    setTimeout(() => {
      setSuccess("");
      setError("");
    }, 5000);
  }, [success, error]);

  const handleDelete = async (id) => {
    try {
      await deleteArticle(id);
      setListArticles(listArticles.filter((article) => article._id !== id));
      setSuccess("Article supprimé avec succès");
    } catch (error) {
      setError("Erreur lors de la suppression de l'article");
      console.error("Erreur lors de la suppression de l'article:", error);
    }
  };

  // Pagination calculations
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = listFilteredArticles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(listFilteredArticles.length / articlesPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  return (
    <section className="h-screen w-full overflow-hidden py-24">
      <div className="container mx-auto flex justify-between items-center border-b border-bleu pb-6">
        <Rechercher search={search} setSearch={setSearch} />
        <Link to={"/admin/addBlog"}>
          <button className="bg-bleu text-white font-bold px-4 py-2 rounded shadow">
            Ajouter
          </button>
        </Link>
      </div>
      <div className="py-4 container mx-auto">
        <h2 className="text-2xl font-bold text-center text-bleu py-2">
          Liste des articles
        </h2>
        {error && (
          <p className="bg-red-100 rounded border border-red-500 text-red-500 my-4 px-4 py-2">
            {error}
          </p>
        )}
        {success && (
          <p className="bg-green-100 rounded border border-green-500 text-green-500 my-4 px-4 py-2">
            {success}
          </p>
        )}
        <table className="w-full">
          <thead>
            <tr className="bg-bleu text-white">
              <th>Titre</th>
              <th>Date de Création</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!listArticles.length ? (
              <tr>
                <td
                  colSpan="3"
                  className="text-center py-4 font-bold text-bleu border-b-2 border-jaune"
                >
                  Aucun article trouvé
                </td>
              </tr>
            ) : (
              currentArticles.map((article) => (
                <ArticleCard
                  key={article._id}
                  article={article}
                  onDelete={handleDelete}
                />
              ))
            )}
            {search !== "" && listFilteredArticles.length === 0 && (
              <tr>
                <td
                  colSpan="3"
                  className="text-center py-4 font-bold text-bleu border-b-2 border-jaune"
                >
                  Aucun résultat trouvé
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          <nav>
            <ul className="flex list-style-none">
              {pageNumbers.map((number) => (
                <li key={number} className="mx-1">
                  <button
                    onClick={() => setCurrentPage(number)}
                    className={`px-4 py-2 rounded-md ${
                      number === currentPage
                        ? "bg-bleu text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    {number}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
}

export default GestionArticles;
