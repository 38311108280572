import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useParams, Link } from "react-router-dom";
import {
  getArticle,
  incrementLecture,
  incrementLike,
} from "../services/article";

function Article() {
  const { _id } = useParams();
  const [article, setArticle] = useState(null);
  const [error, setError] = useState("");
  const [hasLiked, setHasLiked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!_id) {
        setError("L'ID de l'article est manquant dans l'URL.");
        return;
      }

      try {
        const response = await getArticle(_id);
        if (response) {
          const base64String = btoa(
            new Uint8Array(response.image.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          response.image = `data:image/jpeg;base64,${base64String}`;
          setArticle(response);

          // Incrémenter les lectures
          await incrementLecture(_id);
        } else {
          throw new Error("Aucune donnée reçue");
        }
      } catch (error) {
        setError(
          "Impossible de charger l'article. Veuillez réessayer plus tard."
        );
      }
    };

    fetchData();
  }, [_id]);

  const handleLike = async () => {
    try {
      await incrementLike(_id);
      setArticle((prevArticle) => ({
        ...prevArticle,
        likes: prevArticle.likes + 1,
      }));
      setHasLiked(true);
    } catch (error) {
      console.error("Erreur lors de l'ajout du like:", error);
    }
  };

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  if (!article) {
    return <div className="text-center">Chargement de l'article...</div>;
  }

  // LinkedIn Share URL
  const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    window.location.href
  )}`;

  return (
    <div className="bg-fond">
      <Header />
      <div className="min-h-screen overflow-hidden container py-8 lg:py-24 mx-auto">
        <div className="max-w-3xl rounded-xl mx-auto overflow-hidden">
          <img
            src={article.image || "https://via.placeholder.com/150"}
            alt={`Vue de ${article.titre}`}
            className="w-full h-96 object-cover object-center"
          />
        </div>
        <div className="mt-6">
          <h1 className="text-3xl font-bold text-center text-bleu">
            {article.titre}
          </h1>
          <div
            className="mt-4 text-lg text-justify prose min-w-full"
            dangerouslySetInnerHTML={{ __html: article.texte }}
          />
        </div>
        <div className="space-x-2 text-center mt-4">
          <Link to="/actualites">
            <button className="bg-bleu text-white px-4 py-2 mt-4 rounded-md">
              Retour
            </button>
          </Link>
          <a
            href={shareUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-bleu text-white px-4 py-2 mt-4 rounded-md"
          >
            Partager sur LinkedIn
          </a>
          <div className="mt-4 text-center">
            <p>Lectures: {article.lectures}</p>
            <p>Likes: {article.likes}</p>
            <button
              onClick={handleLike}
              disabled={hasLiked}
              className="bg-bleu text-white px-4 py-2 mt-4 rounded-md"
            >
              {hasLiked ? "Déjà aimé" : "Aimer"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Article;
