import React from "react";
import Slider from "react-slick";
import SliderItem from "./SliderItem";
import "slick-carousel/slick/slick.css";

function HeroActo({ data }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <section className="slider-container  overflow-hidden py-8 lg:py-22  mx-auto lg:mt-14 ">
      <Slider {...settings}>
        {data.map(
          (article, index) =>
            index < 3 && <SliderItem key={article._id} article={article} />
        )}
      </Slider>
    </section>
  );
}

export default HeroActo;
