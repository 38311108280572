import { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function NavBar() {
  const [activeLink, setActiveLink] = useState("accueil");
  const [navbar, setNavbar] = useState(false);
  const [subMenu, setSubMenu] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.offsetHeight;
        if (window.scrollY >= top && window.scrollY < top + height) {
          setActiveLink(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setActiveLink]);

  const showNav = () => {
    setNavbar((prevShowNavBar) => !prevShowNavBar);
  };

  const showSubMenu = () => {
    setSubMenu((prevShowSubMenu) => !prevShowSubMenu);
  };

  const active =
    "p-2 text-bleu font-extrabold font-inter hover:text-bleu whitespace-nowrap";
  const inactive =
    "p-2 text-gris font-extrabold font-inter hover:text-bleu whitespace-nowrap";

  return (
    <header className="flex w-full justify-between lg:justify-around items-center fixed bg-fond py-2 shadow z-40 lg:py-4 px-4">
      <a href="https://nicomaticsenegal.com/">
        <img
          src={process.env.PUBLIC_URL + "/assets/Senegal.png"}
          alt="logo"
          id="logo"
          className="w-48"
        />
      </a>
      <GiHamburgerMenu
        className="text-bleu text-2xl lg:hidden cursor-pointer"
        onClick={showNav}
      />
      <nav
        role="navigation"
        className={`${navbar ? "block" : "hidden"} lg:block`}
      >
        <ul className="absolute inset-0 h-screen bg-fond items-center justify-center lg:h-fit lg:relative flex-col lg:flex-row flex lg:justify-around w-full space-x-8 font-extrabold text-gris">
          <FaXmark
            onClick={showNav}
            className="text-bleu text-2xl lg:hidden cursor-pointer absolute right-2 top-2"
          />
          <li className=" relative">
            <a
              href="/admin/home"
              className={activeLink === "accueil" ? active : inactive}
              onClick={showSubMenu}
            >
              Accueil
            </a>
          </li>
          <li>
            <Link
              to="/admin/gesAdmin"
              className={activeLink === "gesAdmin" ? active : inactive}
            >
              Gestion Admins
            </Link>
          </li>
          <li>
            <Link
              to="/admin/gesBlog"
              className={activeLink === "gesBlog" ? active : inactive}
            >
              Gestion Blog
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
