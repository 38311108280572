import React from "react";

function MotCeo() {
    return (
        <section
            id="accueil"
            className="w-full py-24 lg:py-32 container mx-auto lg:px-24"
        >
            <h1 className="text-xl lg:text-2xl font-extrabold text-gris my-4 text-center">
               Nicomatic Sénégal oeuvre pour une industrie performante
            </h1>
            <video
                autoPlay
                loop
                muted
                className="px-2 lg:max-w-4xl rounded-xl mx-auto"
                alt="Video"
            >
                <source src={process.env.PUBLIC_URL + "/assets/video.mp4"} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </section>
    );
}

export default MotCeo;
