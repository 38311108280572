import zoneImage from "../assets/zoneImage.png"


export default function Stat()
{
    return(
        <>
            <section id="zoneSection">
                <div class="w-screen bg-bleu">
                    <img src={zoneImage} alt="bandeBleue" className="mx-auto"/>
                </div>
            </section>




        </>

    )
}