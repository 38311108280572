import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Icône de départ
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Icône d'arrivée
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

export default function CustomizedTimeline() {
  return (
    <>
    <h1 className="text-3xl text-center font-bold  font-inter"> Notre Histoire </h1>
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
         2022 
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
          <ArrowForwardIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2, textAlign: 'justify' }}>
          <Typography variant="h6" component="span" sx={{ display: 'block', mb: 2 }}>
           L'exploration de Nicomatic au Sénégal
          </Typography>
          <Typography component="span">Tout a commencé par une phase d'exploration des <strong>possibilités de partenariat entre les établissements supérieurs d'enseignement et notre entreprise</strong>. Notre naissance a eu lieu à <strong>l'École Polytechnique de Dakar</strong>, un cadre idéal pour cette exploration allant bien au-delà du simple transfert de compétences techniques. Ce partenariat initial s'est rapidement élargi pour inclure d'autres institutions supérieures formant <strong>des ingénieurs en mécanique, informatique et électronique</strong>. Nous nous engageons à <strong>partager notre savoir-faire et nos expertises</strong>, créant ainsi des <strong>liens essentiels pour le développement de l'industrie sénégalaise</strong>. C'est l'initiative G-NI's Factory, créée en 2022.</Typography>
          </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
           sx={{ m: 'auto 0', textAlign: 'center' }}
          variant="body2"
          color="text.secondary"
        >
          2024

        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
          <CheckCircleIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2, textAlign:  'justify !important' }}>
          <Typography variant="h6" component="span" sx={{ display: 'block', mb: 2 }}>
          De G-NI’s Factory à la filiale Nicomatic Sénégal
          </Typography>
          <Typography component="span">La mission initiale de G-NI’s Factory étant <strong>d'accompagner les startups innovantes,</strong> souvent en manque de moyens, dans la concrétisation de leurs idées de fabrication. Nicomatic met à disposition <strong>son expertise et ses connaissances</strong> pour les aider à prototyper leurs produits. Au fil du temps, <strong>nos partenariats ont évolué vers la fabrication de machines industrielles,</strong> reflétant notre engagement envers le développement de l'industrie locale. En 2024, <strong>G-NI's Factory est devenue officiellement une filiale du groupe Nicomatic,</strong> un leader mondialement reconnu dans la connectique pour les secteurs de l'aérospatiale, du spatial et du militaire, présent dans plus de 20 pays.</Typography>
        </TimelineContent>
      </TimelineItem>
     
    </Timeline>
    </>
  );
}
