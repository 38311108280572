import React from 'react'
import NavBar from './components/NavBar'
import { Outlet } from 'react-router'

function HomeAdmin() {
  return (
    <section className='h-screen w-full overflow-hidden'>
      <NavBar/>
      <div className=' py-24 text-bleu font-bold text-3xl'>
          home
      </div>
    </section>
  )
}

export default HomeAdmin
