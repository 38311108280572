import React from "react";
import { Link } from "react-router-dom";

function Activite() {
  return (
    <section
      id="Activite"
      className="py-10 px-0 lg:py-22 container mx-auto lg:px-14 relative"
    >
      <div className="w-full mb-8 z-50">
        <h1 className="text-3xl text-center font-bold font-inter mb-4">
          Nos Services
        </h1>
        <p className="text-xl text-center lg:text-left">
          Au Sénégal, Nicomatic a construit un réseau solide de partenaires pour
          mettre son savoir-faire au service du développement industriel. Grâce
          à ces collaborations, nous offrons une multitude de possibilités...
        </p>
      </div>
      <div className="grid grid-cols-1 py-10 mb-4 md:grid-cols-3 gap-y-8 gap-x-2 lg:grid-cols-3 lg:gap-x-0 lg:gap-y-0 relative z-10">
        <a
          href="https://consulting.nicomaticsenegal.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-full bg-fond shadow-lg p-6 rounded-lg cursor-pointer lg:w-96 lg:h-64 lg:p-4 md:w-auto md:h-auto md:p-1 z-10">
            <div className="flex items-center space-x-4 mb-4 md:mb-1">
              <img
                src={process.env.PUBLIC_URL + "/assets/target.svg"}
                alt="icons consulting"
                className="w-12 h-12"
              />
              <h1 className="text-bleu font-semibold text-lg lg:text-xl">
                Consulting
              </h1>
            </div>
            <p className="text-sm lg:text-md text-justify">
              Boostez votre performance avec notre expertise en IT, incluant{" "}
              <strong>
                l'édition de logiciels et d’applications web et mobiles, la
                gestion de projets de données
              </strong>
              , ainsi que la maintenance applicative et évolutive. Simplifiez
              vos <strong>achats industriels</strong> grâce à notre service de
              Sourcing. En ingénierie, bénéficiez de nos{" "}
              <strong>
                prestations de design, conception, prototypage et réalisation de
                produits et composants
              </strong>{" "}
              pour les industries...{" "}
              <Link
                to="https://consulting.nicomaticsenegal.com"
                className=" bg-bleu rounded-lg px-2 mx-2"
              >
                Voir plus
              </Link>
            </p>
          </div>
        </a>
        <div className="hidden md:flex items-center justify-center relative col-span-1 md:col-span-2 lg:col-span-1 lg:col-start-2 lg:row-span-2 z-0">
          <div className="w-48 h-48 rounded-full bg-bleu text-white font-bold text-center flex justify-center items-center text-xl z-10">
            <p>Pour une industrie vertueuse et performante</p>
          </div>
        </div>
        <a
          href="https://factory.nicomaticsenegal.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-full bg-fond shadow-lg py-10 rounded-lg cursor-pointer lg:w-96 lg:h-64 lg:p-4 md:w-auto md:h-auto md:p-1 z-10">
            <div className="flex items-center space-x-4 mb-4 md:mb-1">
              <img
                src={process.env.PUBLIC_URL + "/assets/actory.svg"}
                alt="icons factory"
                className="w-12 h-12"
              />
              <h1 className="text-bleu font-semibold text-lg lg:text-xl">
                Factory
              </h1>
            </div>
            <p className="text-sm lg:text-md text-justify">
              Découvrez notre offre unique au Sénégal de{" "}
              <strong>
                fabrication sur mesure de machines de transformation agricole.
              </strong>{" "}
              Nous prenons en charge le design, la conception, la réalisation et
              la maintenance de machines performantes, parfaitement adaptées aux
              besoins de chaque client...
              <Link
                to="https://factory.nicomaticsenegal.com"
                className=" bg-bleu rounded-lg px-2 mx-2"
              >
                Voir plus
              </Link>
            </p>
          </div>
        </a>
        <a
          href="https://studio.nicomaticsenegal.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-full md:w-96 md:h-96 bg-fond shadow-lg p-0 mt-4 rounded-lg cursor-pointer lg:w-96 lg:h-64 lg:p-2 md:w-auto md:h-auto md:p-1 z-10">
            <div className="flex items-center space-x-4 mb-4 md:mb-1">
              <img
                src={process.env.PUBLIC_URL + "/assets/studio.svg"}
                alt="icons studio"
                className="w-12 h-12"
              />
              <h1 className="text-bleu font-semibold text-lg lg:text-xl">
                Studio
              </h1>
            </div>
            <p className="text-sm lg:text-md text-justify">
              Accélérez le{" "}
              <strong>
                développement de votre startup grâce à notre accompagnement sur
                mesure pendant trois ans.
              </strong>{" "}
              Nous vous aidons dans le développement de vos produits et
              services, la mise en place de votre stratégie et mettons nos
              compétences ainsi que notre réseau à votre disposition pour
              garantir votre succès...{" "}
              <Link
                to="https://studio.nicomaticsenegal.com"
                className=" bg-bleu rounded-lg px-2 mx-2"
              >
                Voir plus
              </Link>
            </p>
          </div>
        </a>
        <a
          href="https://campus.nicomaticsenegal.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-full bg-fond shadow-lg p-0 mt-4 rounded-lg cursor-pointer lg:w-96 lg:h-64 md:w-auto md:h-auto md:p-1 lg:p-4 z-10">
            <div className="flex items-center space-x-4 mb-4 md:mb-1">
              <img
                src={process.env.PUBLIC_URL + "/assets/campus.svg"}
                alt="icons campus"
                className="w-12 h-12"
              />
              <h1 className="text-bleu font-semibold text-lg lg:text-xl">
                Campus
              </h1>
            </div>
            <p className="text-sm lg:text-md text-justify">
              Développez vos compétences avec notre programme{" "}
              <strong>d'acquisition d'expertise</strong> auprès des meilleures
              industries internationales. Nous offrons aux jeunes ingénieurs
              diplômés l'opportunité d'acquérir{" "}
              <strong>une expérience significative</strong> grâce à des missions
              de deux ans maximum chez un industriel mature de notre réseau. À
              l'issue de cette période,{" "}
              <strong>
                ils rejoignent notre équipe pour travailler sur la partie
                consulting
              </strong>
              ...
            </p>
            <Link
              to="https://campus.nicomaticsenegal.com"
              className=" bg-bleu rounded-lg  px-2 mx-2"
            >
              Voir plus
            </Link>
          </div>
        </a>
      </div>
      <p className="text-xl text-center lg:text-left">
        Notre engagement est de fournir des solutions innovantes et de haute
        qualité, adaptées aux besoins spécifiques de nos clients. Rejoignez-nous
        dans cette aventure passionnante et découvrez comment nous pouvons
        contribuer à votre succès.
      </p>
    </section>
  );
}

export default Activite;
