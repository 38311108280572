import axios from "axios";
const token = localStorage.getItem("token");
const apiUrl = process.env.REACT_APP_API_URL;

export const addArticle = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/api/articles`, data, {
      // Notez 'articles' ici
      headers: {
        "Content-Type": "multipart/form-data", // Bon pour le téléchargement de fichiers
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification dans l'en-tête
      },
    });
    console.log("Request Data:", data);
    console.log(response);
    return response;
  } catch (error) {
    console.error(
      "Error adding article:",
      error.response ? error.response.data : "No response"
    );
    throw error; // Il est souvent utile de renvoyer l'erreur pour une gestion plus poussée en amont
  }
};

export const getArticles = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/articles`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error getting articles:",
      error.response ? error.response.data : "No response"
    );
    throw error;
  }
};

export const getArticle = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/articles/${id}`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error getting article:",
      error.response ? error.response.data : "No response"
    );
    throw error;
  }
};

export const deleteArticle = async (id) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/articles/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("Token:", token);

    console.log(response);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting article:",
      error.response ? error.response.data : "No response"
    );
    throw error;
  }
};

// services/article.js

export const incrementLike = async (articleId) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/articles/${articleId}/like`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Ajoutez le token si nécessaire
        },
      }
    );
    console.log(response);
  } catch (error) {
    console.error(
      "Error incrementing like:",
      error.response ? error.response.data : "No response"
    );
    throw error;
  }
};
export const incrementLecture = async (articleId) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/articles/${articleId}/lecture`,
      {}, // Pas de données à envoyer pour cette requête
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Ajoutez le token si nécessaire
        },
      }
    );
    console.log(response); // Affiche la réponse pour le débogage
    return response.data; // Retourne la réponse des données si nécessaire
  } catch (error) {
    console.error(
      "Error incrementing lecture:",
      error.response ? error.response.data : "No response"
    );
    throw error; // Relève l'erreur pour un traitement plus approfondi
  }
};
