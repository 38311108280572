import React, { useState } from "react";
import axios from "axios";
import { FaFileUpload } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importer le style de Quill
import { useNavigate } from "react-router-dom"; // Importer useNavigate

function AddBlog() {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [titre, setTitre] = useState("");
  const [corps, setCorps] = useState("");
  const [tags, setTags] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [listTags, setListTags] = useState("");
  const navigate = useNavigate(); // Initialiser useNavigate

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Récupérer le token ici
    try {
      const formData = new FormData();
      formData.append("titre", titre);
      formData.append("texte", corps);
      formData.append("image", imageFile);
      formData.append("tags", JSON.stringify(tags));

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/articles`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Assurez-vous que le token est bien récupéré
          },
        }
      );

      if (response.status === 201) {
        setTitre("");
        setCorps("");
        setImageFile(null);
        setImagePreview("");
        setTags([]);
        setListTags("");
        setSuccess("Article ajouté avec succès");

        // Redirection vers la page des articles ou la page d'accueil après succès
        // Remplacez "/actualites" par la route souhaitée
        navigate("/admin/addBlog");
      }
      navigate("/admin/addBlog");
    } catch (error) {
      setError(
        "Erreur lors de l'ajout de l'article : " +
          (error.response ? error.response.data : error.message)
      );
    }
  };

  return (
    <section className="container mx-auto h-screen py-24 overflow-y-auto">
      <h1 className="text-2xl font-bold text-bleu py-2">Ajouter un blog</h1>
      <div>
        {error && (
          <p className="bg-red-100 rounded border border-red-500 text-red-500 mb-4 px-4 py-2">
            {error}
          </p>
        )}
        {success && (
          <p className="bg-green-100 rounded border border-green-500 text-green-500 mb-4 px-4 py-2">
            {success}
          </p>
        )}
        <form className="w-full space-y-4" onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <label htmlFor="image" className="text-bleu font-semibold">
              {!imagePreview && (
                <div className="flex items-center justify-center flex-col size-48 border-2 border-bleu border-dotted">
                  <FaFileUpload className="text-2xl" /> Ajouter une Image
                </div>
              )}
            </label>
            <input
              type="file"
              id="image"
              name="image"
              className="hidden border border-jaune rounded-md p-2 outline-none"
              onChange={handleImageChange}
            />
            {imagePreview && (
              <label htmlFor="image" className="cursor-pointer">
                <img src={imagePreview} alt="Uploaded" className="mt-2 w-2/6" />
              </label>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="titre" className="text-bleu font-semibold">
              Titre
            </label>
            <input
              type="text"
              id="titre"
              name="titre"
              placeholder="Titre de l'article"
              className="border border-jaune rounded-md p-2 outline-none"
              value={titre}
              onChange={(e) => setTitre(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-bleu font-semibold">
              Corps de L'article
            </label>
            <ReactQuill
              value={corps}
              onChange={(value) => setCorps(value)}
              className="border border-jaune rounded-md"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="tags" className="text-bleu font-semibold">
              Mot-clés *(
              <span className="font-normal">
                Sépare les mot-clés par un point-virgule(;) Ex : Transformation;
                Industrie; Mécanique
              </span>
              )*
            </label>
            <input
              type="text"
              id="tags"
              name="tags"
              placeholder="Mettre les tags"
              className="border border-jaune rounded-md p-2 outline-none"
              value={listTags}
              onChange={(e) => setListTags(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="bg-bleu text-white font-bold px-4 py-2 rounded shadow"
          >
            Ajouter
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddBlog;
