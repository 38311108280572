import React from 'react'

function Rechercher({search, setSearch}) {
    
    const handleChange = (e) => {
        setSearch(e.target.value);
    }

  return (
    <div>
    <input type="text" placeholder="Search" className=" py-1 px-2 border-2 border-bleu outline-none rounded" value={search} onChange={handleChange} />
    </div>
  )
}

export default Rechercher