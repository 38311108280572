import React from "react";

function LayoutHero({ id, title, texte, image, position }) {
    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="flex items-center justify-center max-w-xl text-xl lg:text-2xl font-extrabold text-gris my-2 mx-24 text-center">
            {title}
            </h1>
        <section
            id={id}
            className="py-10  lg:py-25 container mx-auto lg:px-24 flex flex-col lg:flex-row justify-between  "
        >
             
            <div className={`relative lg:order-${position} z-0`}>
                <div className=" size-96 lg:size-96 mx-auto lg:mx-0 lg:gap-4 lg:order-last z-20 bg-white rounded-xl overflow-hidden">
                    <img
                        src={process.env.PUBLIC_URL + image}
                        alt={title}
                        className="h-96 w-full object-cover object-center"
                    />
                </div>
                {
                    position === 'first' ? (
                        <div className="absolute -bottom-20 -right-20 size-44 lg:size-56 bg-jaune rounded-full blur -z-10 animate-pulse"></div>
                    ) : (
                        <div className="absolute -bottom-20 -right-20 size-44 lg:size-56 bg-bleu rounded-full blur -z-10 animate-pulse"></div>
                    )
                }
            </div>
            <div className="px-10 mt-8 z-0 lg:ml-12" >
               
            <p
                        className="text-xl max-w-2xl font-semibold text-justify"
                        dangerouslySetInnerHTML={{ __html: texte }}
                    />
            </div>
        </section>
        </div>
    );
}

export default LayoutHero;
