import React from 'react'
import { FaSearch } from "react-icons/fa";
import { PiSelectionBackgroundBold } from "react-icons/pi";
function ResearchActu({search, setSearch}) {
return (
    <section className="  overflow-hidden container mx-auto relative">
            <div className=' w-full mt-6 border-b-2 border-bleu pb-4'>
                    <div className="relative w-full">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                    <button type="button" title="search" className="p-1 focus:outline-none focus:ring">
                                    <FaSearch className='text-bleu text-xl lg:text-2xl' />
                                    </button>
                            </span>
                            <input type="text" name="Search" placeholder="Rechercher ..." value={search} onChange={(e) => setSearch(e.target.value)} className=" w-96 py-3 lg:text-xl pl-10 bg-fond rounded-md focus:outline-none border-2 border-bleu  " />
                    </div>
            </div>
            {/* <div>
                    
                    <div className="  flex flex-col lg:flex-row justify-around items-center flex-wrap  gap-4 lg:gap-8 border-y-2 border-y-bleu mt-4">
                            <button className=' rounded-full px-4 py-2 text-bleu font-bold text-left mt-4  flex items-center gap-2 m-4 text-xs'>
                            <PiSelectionBackgroundBold className=' text-2xl text-jaune' />    Transformation
                            </button>
                            <button className=' rounded-full px-4 py-2 text-bleu font-bold text-left mt-4  flex items-center gap-2 m-4 text-xs'>
                            <PiSelectionBackgroundBold className=' text-2xl text-jaune' />    Marketing Digital
                            </button>
                            <button className=' rounded-full px-4 py-2 text-bleu font-bold text-left mt-4  flex items-center gap-2 m-4 text-xs'>
                            <PiSelectionBackgroundBold className=' text-2xl text-jaune' />    Industrie
                            </button>
                            <button className=' rounded-full px-4 py-2 text-bleu font-bold text-left mt-4  flex items-center gap-2 m-4 text-xs'>
                            <PiSelectionBackgroundBold className=' text-2xl text-jaune' />    Actualité de Nicomatic Sénégal
                            </button>
                            <button className=' rounded-full px-4 py-2 text-bleu font-bold text-left mt-4  flex items-center gap-2 m-4 text-xs'>
                            <PiSelectionBackgroundBold className=' text-2xl text-jaune' />    Education
                            </button>
                    </div> 
            </div>*/}
    </section>
)
}

export default ResearchActu
