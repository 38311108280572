import React, { useState } from "react";
import axios from 'axios'; // Assurez-vous d'installer axios avec `npm install axios`
import { FaXmark } from "react-icons/fa6";

function ContactMe() {
    const [showForm, setShowForm] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [userMessage, setUserMessage] = useState('');

    const handleShowForm = () => {
        setShowForm((prevShowform) => !prevShowform);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!name || !email || !tel || !message) {
            setUserMessage('Tous les champs sont obligatoires.');
            return;
        }

        try {
            const response = await axios.post('https://backend.nicomaticsenegal.com/api/contact', {
                name,
                email,
                tel,
                message
            });

            console.log(response.data);
            setShowForm(false);
            setUserMessage('Votre message a été envoyé avec succès.');
            // Réinitialiser les champs du formulaire
            setName('');
            setEmail('');
            setTel('');
            setMessage('');
        } catch (error) {
            console.error(error);
            setUserMessage('Une erreur s\'est produite lors de l\'envoi de votre message.');
        }
    };

    return (
        <div className="fixed bottom-0 right-0 z-50 cursor-pointer">
            <img
                src={process.env.PUBLIC_URL + "/assets/contact.svg"}
                alt="contact"
                onClick={handleShowForm}
                className={
                    showForm
                        ? "hidden"
                        : " size-28 p-4 lg:h-full lg:w-full object-cover object-center lg:p-8 "
                }
            />
            <div
                className={
                    showForm
                        ? "max-w-96 px-4 py-16 rounded-md bg-fond shadow-lg relative"
                        : "hidden"
                }
            >
                <FaXmark
                    onClick={handleShowForm}
                    className="absolute top-4 right-4 text-bleu text-xl cursor-pointer"
                />
                <h1 className="text-xl font-bold mb-6">Contactez-nous</h1>

                {userMessage && (
                    <div className="mb-4 text-sm text-center text-red-500">
                        {userMessage}
                    </div>
                )}

                <form
                    noValidate=""
                    onSubmit={handleSubmit}
                    className="self-stretch space-y-3"
                >
                    <div>
                        <label htmlFor="name" className="text-sm">
                            Prénom et Nom
                        </label>
                        <input
                            id="name"
                            type="text"
                            placeholder="Prénom"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full rounded-md focus:ring focus:dark:ring-jaune border-bleu outline-none p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="text-sm">
                            Email
                        </label>
                        <input
                            id="email"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full rounded-md focus:ring focus:dark:ring-jaune border-bleu outline-none p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="tel" className="text-sm">
                            Téléphone
                        </label>
                        <input
                            id="tel"
                            type="tel"
                            placeholder="Tel"
                            value={tel}
                            onChange={(e) => setTel(e.target.value)}
                            className="w-full rounded-md focus:ring focus:dark:ring-jaune border-bleu outline-none p-2"
                            pattern="[0-9]"
                        />
                    </div>
                    <div>
                        <label htmlFor="message" className="text-sm">
                            Message
                        </label>
                        <textarea
                            id="message"
                            placeholder="Votre message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="w-full rounded-md focus:ring focus:dark:ring-jaune border-bleu outline-none p-2"
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full py-2 font-semibold rounded dark:bg-jaune dark:text-gray-50"
                    >
                        Envoyer
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ContactMe;
