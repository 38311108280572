import React, { useEffect, useState } from "react";
import ActuCard from "./ActuCard";
import { Link } from "react-router-dom";
import { getArticles } from "../services/article";

function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function ActuList() {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getArticles();
        const articlesWithImages = data.map((article) => ({
          ...article,
          image: article.image
            ? `data:image/jpeg;base64,${arrayBufferToBase64(
                article.image.data
              )}`
            : "https://via.placeholder.com/150",
        }));
        setArticles(articlesWithImages);
      } catch (error) {
        console.error("Erreur lors de la récupération des articles:", error);
        setError(
          "Impossible de charger les articles. Veuillez réessayer plus tard."
        );
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  if (articles.length === 0) {
    return <div className="text-center">Chargement des articles...</div>;
  }

  return (
    <section className="overflow-hidden py-24 lg:py-32 container mx-auto lg:px-22 relative">
      <h2 className="max-w-xl text-justify text-lg font-bold lg:text-xl text-bleu my-4 border-l-8 border-jaune px-2 lg:px-4">
        Découvrez les dernières actualités de Nicomatic Sénégal
      </h2>
      <div className="flex flex-col lg:flex-row justify-around items-center flex-wrap gap-8">
        {articles.slice(0, 3).map((article) => (
          <ActuCard key={article._id} article={article} />
        ))}
      </div>
      <div className="mt-8 max-lg:hidden lg:max-h-20 relative">
        <div>
          <Link
            to="/actualites"
            className="mt-6 text-base font-semibold text-white bg-bleu px-4 py-2 rounded hover:text-jaune text-center block absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          >
            Accéder au blog
          </Link>
        </div>
        <div className="opacity-35 flex flex-col lg:flex-row justify-around items-center flex-wrap gap-8">
          {articles.slice(3).map((article) => (
            <ActuCard key={article._id} article={article} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ActuList;
