// components/ArticleCard.js
import React from "react";

const ArticleCard = ({ article, onDelete }) => {
  const formattedDate = new Date(article.createdAt).toLocaleDateString(
    "fr-FR",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );

  return (
    <tr>
      <td>{article.titre}</td>
      <td>{formattedDate}</td>
      <td>
        <button
          onClick={() => onDelete(article._id)}
          className="bg-red-500 text-white font-bold px-4 py-2 rounded shadow"
        >
          Supprimer
        </button>
      </td>
    </tr>
  );
};

export default ArticleCard;
