import { Link } from "react-router-dom";

export default function ActuCard({ article }) {
  if (!article || !article._id) {
    return <div>Article non trouvé</div>;
  }
  return (
    <Link to={`/actualites/${article._id}`}>
      <div
        className={
          "max-w-sm p-4 rounded-md shadow-md dark:bg-gray-50 dark:text-gray-900 hover:cursor-pointer hover:shadow-xl"
        }
      >
        {
          <img
            src={article.image || "https://via.placeholder.com/150"}
            alt={article.titre}
            className="w-full h-48 object-cover"
          />
        }
        <div className="mt-4 mb-2">
          {/* <h3 className="text-xs font-semibold text-bleu uppercase">
                    {tags}
                </h3> */}
          <h2 className="text-sm font-semibold tracking-wide">
            {article.titre}
          </h2>
        </div>
      </div>
    </Link>
  );
}
