import React from 'react'
import NavBar from './components/NavBar'
import { Outlet } from 'react-router'

function AdminLayout() {
  return (
    <section className='h-screen w-full overflow-hidden'>
      <NavBar/>
      <Outlet/>
    </section>
  )
}

export default AdminLayout
