import React from "react";
import HeaderAbout from "../components/HeaderAbout";
import HeroAbout from "../components/HeroAbout";
import LayoutHero from "../components/LayoutHero";
import MotCeo from "../components/MotCeo";
import Teams from "../components/Teams";
import Footer from "../components/Footer";
import ContactMe from "../components/ContactMe";

function About() {
    const id1 = "Apropos1";
    const id2 = "Apropos2";
    return <div  className=" overflow-hidden bg-fond ">
        <HeaderAbout/>
        <ContactMe/>
        <HeroAbout/>
        <LayoutHero
                id={id1}
                title={"Notre Contribution au Développement d’une industrie vertueuse et performante "}
                texte={` <strong>Nicomatic Sénégal</strong> utilise les leviers humains et technologiques pour le développement de l'industrie au Sénégal. Avec <strong>CAMPUS</strong> et <strong>CONSULTING,</strong> nous mettons notre expertise en développement de logiciels, applications web et mobiles, et sourcing industriel au service de la performance. Grâce à <strong>STUDIO,</strong> nous aidons les startups innovantes à se développer en accompagnant leurs projets de transformation agricole. Enfin, avec <strong>FACTORY,</strong> nous fournissons aux industriels les machines de transformation agricole sur mesure nécessaires à leur succès. <strong>Ensemble, ces activités créent une synergie qui propulse l'innovation et la croissance de l'industrie sénégalaise.</strong>`}
                image={"/assets/groupe1.jpg"}
                position={"first"}
            />
            <LayoutHero
                id={id2}
                title={"L'ADN de notre entreprise"}
                texte={` Chez <strong>Nicomatic Sénégal,</strong> notre ADN est façonné par quatre valeurs fondamentales qui guident chacune de nos actions.

                La <strong>confiance en notre capacité à faire</strong> est au cœur de notre approche, nous permettant d'aborder chaque projet avec assurance et détermination. Nous croyons fermement en notre expertise et en notre potentiel à réaliser des solutions innovantes et efficaces.
                
                <strong>L'interconnexion</strong> est une valeur essentielle, symbolisant la création de synergies entre nos experts internationaux et nos partenaires locaux. Cette collaboration enrichissante nous permet de partager et de multiplier les connaissances, les compétences et les ressources, renforçant ainsi notre impact collectif.
                
                `}
                image={"/assets/valeurs.jpg"}
                position={"last"}
            />
            <MotCeo/>
            <Teams/>
            <Footer/>
    </div>;
}

export default About;
