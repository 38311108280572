import React, { useState } from 'react'
import { FaXmark } from "react-icons/fa6";
import { addAdmin } from '../../../services/admin';

function AddAdmin() {
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [admin, setAdmin] = useState({
        prenom: '',
        nom: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        setAdmin({
            ...admin,
            [e.target.name]: e.target.value,
        });
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (admin.password !== admin.confirmPassword) {
            setError("Les mots de passe ne correspondent pas");
        } else {
            const reponse = await addAdmin(admin);
            if (reponse.status === 400) {
                setError("Erreur de l'ajout de l'admin!");
            } else if (reponse.status === 201) {
                setAdmin({
                    prenom: '',
                    nom: '',
                    email: '',
                    password: '',
                });
            setError('');
            setSuccess('Administrateur ajouté avec succès');

            setTimeout(() => {
                setSuccess('');
                setShowModal(false);
            }, 2000);
            }
        }
    }
    const handleShowModal = () => {
        setShowModal( prevState => !prevState)
    }

return (
    <div className='relative'>
        <button onClick={handleShowModal} className=' bg-bleu text-white font-bold px-4 py-2 rounded shadow'>
            Ajouter
        </button>
        {showModal && (
            <div className="fixed inset-0 flex items-center justify-center z-50 ">
                <div className="bg-fond border-2 border-bleu rounded-lg p-8 relative">
                    <FaXmark className="absolute top-4 right-4 text-bleu cursor-pointer text-2xl" onClick={handleShowModal} />
                    <h2 className="mt-2 text-2xl font-bold mb-4 text-bleu">Ajouter un administrateur</h2>
                    {error && <p className="bg-red-100 rounded border border-red-500 text-red-500 mb-4 px-4 py-2">{error}</p>}
                    {success && <p className="bg-green-100 rounded border border-green-500 text-green-500 mb-4 px-4 py-2">{success}</p>}
                    <form onSubmit={handleFormSubmit}>
                        <div className="mb-1">
                            <label htmlFor="prenom" className="block font-bold mb-2 text-bleu ">Prénom</label>
                            <input type="text" id="prenom" className=" border border-jaune outline-none rounded-md px-3 py-2 w-full" onChange={handleChange} name="prenom" value={admin.prenom} required />
                        </div>
                        <div className="mb-1">
                            <label htmlFor="nom" className="block font-bold mb-2 text-bleu ">Nom</label>
                            <input type="text" id="nom" className="border border-jaune outline-none rounded-md px-3 py-2 w-full" onChange={handleChange} name="nom" value={admin.nom} required />
                        </div>
                        <div className="mb-1">
                            <label htmlFor="email" className="block font-bold mb-2 text-bleu ">Email</label>
                            <input type="email" id="email" className="border border-jaune outline-none rounded-md px-3 py-2 w-full" onChange={handleChange} name="email" value={admin.email} required />
                        </div>
                        <div className="mb-1">
                            <label htmlFor="password" className="block font-bold mb-2 text-bleu ">Mot de passe</label>
                            <input type="password" id="password" className="border border-jaune outline-none rounded-md px-3 py-2 w-full" onChange={handleChange} name="password" value={admin.password} required />
                        </div>
                        <div className="mb-1">
                            <label htmlFor="confirmPassword" className="block font-bold mb-2 text-bleu ">Confirmer le mot de passe</label>
                            <input type="password" id="confirmPassword" className="border border-jaune outline-none rounded-md px-3 py-2 w-full" onChange={handleChange} name="confirmPassword" value={admin.confirmPassword} required />
                        </div>
                        <button type="submit" className="bg-jaune text-bleu font-bold px-4 py-2 rounded shadow mt-4">Ajouter</button>
                    </form>
                </div>
            </div>
        )}
    </div>
)}
export default AddAdmin
