import React from "react";
import { Link } from "react-router-dom";
function HeroAbout() {
    return (
        <section id="accueil">
            <div className="max-h-96 relative flex flex-1 shrink-0 items-center justify-center overflow-hidden bg-gray-100 py-16 shadow-lg md:py-20 xl:py-48">
            
                            <video
                                    src={process.env.PUBLIC_URL + "/assets/video1.mp4"}
                                    alt="equipe"
                                    className="absolute inset-0 max-h-96 w-full h-full object-contain object-center"
                                    autoPlay
                                    loop
                                    muted
                                />

                <div className="absolute inset-0 bg-bleu mix-blend-multiply"></div>

                <div className="relative flex flex-col items-center p-4 sm:max-w-xl">
                    <h1 className="mb-8 text-opacity-50 text-center text-4xl font-bold text-white sm:text-5xl md:mb-12 md:text-6xl">
                        Qui sommes nous ?
                    </h1>
                </div>
            </div>
            <div className=" px-4 py-5 rounded-lg shadow bg-fond -translate-y-2 w-96  mx-auto lg:w-3/5">
                <h1 className=" text-xl lg:text-2xl font-extrabold text-gris my-4">
                    NICOMATIC SENEGAL
                </h1>
                <p className="">
                    Nicomatic Sénégal est un moteur de l'innovation industrielle
                    au Sénégal, offrant des solutions sur mesure pour la
                    fabrication de machines de transformation agricole, le
                    développement de logiciels et applications, l'accompagnement
                    de startups, et l'acquisition d'expertise internationale
                    pour les jeunes ingénieurs, contribuant ainsi activement au
                    développement de l'industrie locale.
                </p>
                <div className=" mt-4 w-fit px-6 py-1 border-2 border-bleu rounded-full font-bold text-white bg-bleu shadow-lg">
                    <Link to="/">Voir plus</Link>
                </div>
            </div>
        </section>
    );
}

export default HeroAbout;
