import React from "react";
import { IoArrowRedo } from "react-icons/io5";
import { Link } from "react-router-dom";
function SliderItem({ article }) {
  return (
    <div className=" w-full max-h-[75vh] relative overflow-hidden grid grid-cols-2  container mx-auto">
      <div>
        {article.image === "" ? (
          <img
            src={"https://via.placeholder.com/150"}
            alt={article.titre}
            className=" object-cover object-center w-full h-full"
          />
        ) : (
          <img
            src={process.env.PUBLIC_URL + article.image}
            alt={article.titre}
            className=" object-cover object-center w-full h-full"
          />
        )}
        {/* <img src={process.env.PUBLIC_URL + "/assets/groupe4.jpg"} alt="" className=" object-cover object-center w-full h-full"/> */}
      </div>
      <div className=" mx-auto flex items-center justify-center h-full"></div>
      <div className=" absolute lg:top-1/2 top-1/4 left-3/4 lg:left-1/2 -translate-x-24 bg-fond lg:shadow-lg blur-20 min-h-48 text-center lg:text-3xl font-bold max-w-lg lg:py-8 lg:rounded-xl px-4">
        <h1 className="text-bleu">{article.titre}</h1>
        <Link to={`/actualites/${article._id}`}>
          <button className=" rounded-full px-4 py-2 bg-bleu text-jaune font-bold text-left mt-4 shadow-md flex items-center gap-2 m-4 text-xs">
            <IoArrowRedo /> Consulter
          </button>
        </Link>
      </div>
    </div>
  );
}

export default SliderItem;
