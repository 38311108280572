import React from 'react'
import { MdDelete } from "react-icons/md";
import { deleteAdmin } from '../../../services/admin';
function AdminCard({admin,setSuccess, setError}) {
    const handleDelete = async () => {
        window.confirm(`Voulez-vous vraiment supprimer ${admin.prenom} ${admin.nom}?`);
        const reponse = await deleteAdmin(admin._id);
        if( reponse.status === 500) {
            setError("Erreur lors de la suprression!");
        } else {
            setSuccess("Administrateur supprimé avec succés!")
        }
    }
  return (
    <tr className=' border-b-2 border-jaune px-4 py-4 font-semibold text-center text-bleu'>
        <td>{admin.prenom} </td>
        <td>{admin.nom}</td>
        <td>{admin.email}</td>
        <td>
            <button onClick={handleDelete} className='text-red-400 font-bold px-4 py-2 rounded shadow'><MdDelete /></button>
        </td>
    </tr>
  )
}

export default AdminCard