import { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Header() {
  const [activeLink, setActiveLink] = useState("accueil");
  const [navbar, setNavbar] = useState(false);
  const [subMenu, setSubMenu] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.offsetHeight;
        if (window.scrollY >= top && window.scrollY < top + height) {
          setActiveLink(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setActiveLink]);

  const showNav = () => {
    setNavbar((prevShowNavBar) => !prevShowNavBar);
  };

  const showSubMenu = () => {
    setSubMenu((prevShowSubMenu) => !prevShowSubMenu);
  };

  const active =
    "p-2 text-bleu font-extrabold font-inter hover:text-bleu whitespace-nowrap";
  const inactive =
    "p-2 text-gris font-extrabold font-inter hover:text-bleu whitespace-nowrap";

  return (
    <header className="flex w-full justify-between lg:justify-around items-center fixed bg-fond py-2 shadow z-50 lg:py-4 px-4">
      <a href="https://nicomaticsenegal.com/">
        <img
          src={process.env.PUBLIC_URL + "/assets/Senegal.png"}
          alt="logo"
          id="logo"
          className="w-48"
        />
      </a>
      <GiHamburgerMenu
        className="text-bleu text-2xl lg:hidden cursor-pointer"
        onClick={showNav}
      />
      <nav
        role="navigation"
        className={`${navbar ? "block" : "hidden"} lg:block`}
      >
        <ul className="absolute inset-0 h-screen bg-fond items-center justify-center lg:h-fit lg:relative flex-col lg:flex-row flex lg:justify-around w-full space-x-8 font-extrabold text-gris">
          <FaXmark
            onClick={showNav}
            className="text-bleu text-2xl lg:hidden cursor-pointer absolute right-2 top-2"
          />
          <li className=" relative">
            <a
              href="/about"
              className={activeLink === "accueil" ? active : inactive}
              onClick={showSubMenu}
            >
              Accueil
            </a>
          </li>
          <li>
            <a
              href="#Apropos1"
              className={activeLink === "Apropos1" ? active : inactive}
            >
              A propos
            </a>
          </li>
          <li>
            <a
              href="#Apropos2"
              className={activeLink === "Apropos2" ? active : inactive}
            >
              Notre ADN
            </a>
          </li>

          <li>
            <a
              href="#equipe"
              className={activeLink === "equipe" ? active : inactive}
            >
              Notre Equipe
            </a>
          </li>
        </ul>
      </nav>
      <div className=" lg:block w-fit px-4 py-2 border-2 border-bleu rounded-full font-bold text-bleu shadow-lg">
        <a href="#contact">Contact</a>
      </div>
    </header>
  );
}
