import { Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import HomeAdmin from "./HomeAdmin";
import ProtectedRoute from "../../context/ProtectedRoute";
import GestionAdmin from "./GestionAdmin";
import AdminLayout from "./AdminLayout";
import GestionBlog from "./GestionBlog";
import AddBlog from "./components/AddBlog";
import AddAdmin from "./components/AddAdmin";

function AdminRouter() {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="register" element={<AddAdmin />} />
      <Route element={<AdminLayout />}>
        <Route path="home" element={<HomeAdmin />} />
        <Route path="gesAdmin" element={<GestionAdmin />} />
        <Route path="gesBlog" element={<GestionBlog />} />
        <Route path="addBlog" element={<AddBlog />} />
      </Route>
      <Route element={<ProtectedRoute />}></Route>
    </Routes>
  );
}

export default AdminRouter;
