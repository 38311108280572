import React from "react";
import "../styles/footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="contact" className="px-4 bg-bleu">
      <div className="container flex flex-col justify-between py-10 gap-20 mx-auto space-y-8 lg:flex-row lg:space-y-0 text-white">
        <div className="lg:w-1/3 space-y-4 ">
          <a
            href="/"
            className="flex flex-col items-center lg:items-start space-y-4"
          >
            <div className="flex items-center justify-center w-48 h-10 overflow-hidden">
              <img
                src={process.env.PUBLIC_URL + "/assets/Senegal-1.png"}
                alt="logo blanc"
              />
            </div>
            <div>
              <p className="max-w-xl text-sm text-justify">
                Nicomatic Sénégal, moteur de l'innovation industrielle, propose
                des solutions sur mesure en machines agricoles, logiciels,
                accompagnement de startups et expertise internationale pour
                jeunes ingénieurs, contribuant au développement local.
              </p>
            </div>
          </a>
        </div>
        <div className="grid grid-cols-2 gap-20 lg:w-2/3 sm:grid-cols-4">
          <div className="space-y-3">
            <h3 className="tracking-wide uppercase font-bold">
              Nicomatic Sénégal
            </h3>
            <ul className="space-y-1 font-semibold">
              <li>
                <a href="#Apropos">Notre Histoire</a>
              </li>
              <li>
                <a href="#Activite">Nos services</a>
              </li>
              <li>
                <a href="#Partenaires">Partenaires</a>
              </li>
              <li>
                <Link to="/about">Qui sommes-nous</Link>
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h3 className="uppercase font-bold">Nos services</h3>
            <ul className="space-y-1 font-semibold">
              <li className="flex items-center space-x-2">
                <img
                  alt="fact"
                  src={process.env.PUBLIC_URL + "/assets/factory1.svg"}
                  className="h-6 w-6"
                />
                <a href="https://factory.nicomaticsenegal.com">Factory</a>
              </li>
              <li className="flex items-center space-x-2">
                <img
                  alt="fact"
                  src={process.env.PUBLIC_URL + "/assets/studio1.svg"}
                  className="h-6 w-6"
                />
                <a href="https://studio.nicomaticsenegal.com">Studio</a>
              </li>
              <li className="flex items-center space-x-2">
                <img
                  alt="fact"
                  src={process.env.PUBLIC_URL + "/assets/target1.svg"}
                  className="h-6 w-6"
                />
                <a href="https://consulting.nicomaticsenegal.com">Consulting</a>
              </li>
              <li className="flex items-center space-x-2">
                <img
                  alt="fact"
                  src={process.env.PUBLIC_URL + "/assets/campus1.svg"}
                  className="h-6 w-6"
                />
                <a href="https://campus.nicomaticsenegal.com">Campus</a>
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h3 className="uppercase font-bold">Suivez-nous</h3>
            <div className="flex space-x-3">
              <a
                href="#"
                target="_blank"
                title="Linkdin"
                className="flex items-center p-1"
              >
                <svg
                  class="h-5 w-5"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="py-6 text-sm text-center text-white">
        © 2024 NICOMATIC-SENEGAL. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
