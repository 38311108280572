import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ContactMe from "../components/ContactMe";
import HeroActo from "../components/HeroActo";
import ActuListPage from "../components/ActuListPage";
import ResearchActu from "../components/ResearchActu";
import { getArticles } from "../services/article";

function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function Actualite() {
  const [search, setSearch] = useState("");
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      try {
        const response = await getArticles();
        console.log("Articles fetched from API:", response);
        const articlesWithImages = response.map((article) => ({
          ...article,
          image: article.image
            ? `data:image/jpeg;base64,${arrayBufferToBase64(
                article.image.data
              )}`
            : "https://via.placeholder.com/150",
        }));
        console.log("Articles with images:", articlesWithImages);
        setArticles(articlesWithImages);
        setFilteredArticles(articlesWithImages);
      } catch (error) {
        console.error("Erreur lors de la récupération des articles:", error);
        setError("Échec du chargement des articles.");
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    const filtered = articles.filter((article) =>
      article.titre.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredArticles(filtered);
  }, [search, articles]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="overflow-hidden bg-fond">
      <Header />
      <ContactMe />
      <HeroActo data={articles} />
      <ResearchActu search={search} setSearch={setSearch} />
      <ActuListPage articles={filteredArticles} />
      <Footer />
    </div>
  );
}

export default Actualite;
