import React, {useState, useEffect} from 'react'
import AddAdmin from './components/AddAdmin'
import Rechercher from './components/Rechercher'
import { getAdmins } from '../../services/admin';
import AdminCard from './components/AdminCard';

function GestionAdmin() {
  const [listAdmins, setListAdmins] = useState([]);
  const [listFilteredAdmins, setListFilteredAdmins] = useState(listAdmins);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const fetchData = async () => {
        try {
            const admins = await getAdmins();
            setListAdmins(admins.data);
        } catch (error) {
            setError("Erreur lors de la récupération des admins");
            console.error(
                "Erreur lors de la récupération des admins:",
                error
            );
        }
    };

    fetchData();
   
}, [listAdmins,setListAdmins]);

useEffect(() => {
  if (search === "") {
      setListFilteredAdmins(listAdmins);
  } else {
      const filteredAdmins = listAdmins.filter((admin) => {
          return admin.prenom.toLowerCase().includes(search.toLowerCase()) || admin.nom.toLowerCase().includes(search.toLowerCase());
      });
      setListFilteredAdmins(filteredAdmins);
  }
},[search, listAdmins]);

  useEffect(()=>{
    setTimeout(() => {
      setSuccess('');
      setError('');
  }, 5000);
  })

  return (
    <section className=' h-screen w-full overflow-hidden py-24 '>
        <div className='container mx-auto flex justify-between items-center border-b border-bleu pb-6'>
            <Rechercher search={search} setSearch={setSearch}/>
            <AddAdmin/>
        </div>
        <div className='py-4 container mx-auto'>
          <h2 className='text-2xl font-bold text-center text-bleu py-2'>Liste des administrateurs</h2>
          {error && <p className="bg-red-100 rounded border border-red-500 text-red-500 my-4 px-4 py-2">{error}</p>}
                    {success && <p className="bg-green-100 rounded border border-green-500 text-green-500 my-4 px-4 py-2">{success}</p>}
          <table className='w-full '>
              <thead>
                  <tr className='bg-bleu text-white'>
                      <th>Prénom</th>
                      <th>Nom</th>
                      <th>Email</th>
                      <th>Actions</th>
                  </tr>
              </thead>
              <tbody>
                  {!listAdmins ? (
                      <tr>
                          <td colSpan='4' className='text-center py-4 font-bold text-bleu  border-b-2 border-jaune'>Aucun administrateur trouvé</td>
                      </tr>
                  ) :  (
                      listFilteredAdmins.map((admin) => (
                          <AdminCard key={admin._id} admin={admin} setSuccess={setSuccess} setError={setError} />
                      ))
                  )}
                  {
                    search !== "" && listFilteredAdmins.length === 0 && (
                      <tr>
                          <td colSpan='4' className='text-center py-4 font-bold text-bleu  border-b-2 border-jaune'>Aucun résultat trouvé</td>
                      </tr>
                    )
                  }
              </tbody>
          </table>
      </div>
    </section>
  )
}

export default GestionAdmin
