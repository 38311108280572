import axios from "axios"

const storeToken = (token) => {
    localStorage.setItem("token", token);
}
const token = localStorage.getItem("token");

export const deleteToken = () => {
    localStorage.removeItem("token");
}

export const login = async (data) => {
    
    try {
        const  reponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/login`,{...data});
        if (reponse.status === 200) {
            storeToken(reponse.data.token);
            return reponse;
        }
    } catch (error) {
        console.error(error);
    }
}

export const addAdmin = async (data) => {
    try {
        const reponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/register`,{...data},{
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        return reponse;
    } catch (error) {
        console.error(error);
    }
}

export const getAdmins = async () => {
    try {
        const reponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/`,{
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        return reponse;
    } catch (error) {
        console.error(error);
    }
}

export const deleteAdmin = async (id) => {
    try {
        const reponse = await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/${id}`,{
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        return reponse;
    } catch (error) {
        console.error(error);
    }
}

export const isAuthenticated = async () => {
    if (!token) return false;

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/protected`,{
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        if (response.status === 400 || response.status === 401) {
            return false;
        } else {
            return true
        }
    } catch (error) {
        return false
    }
}